<template>
  <div
    :key="key"
    class="flex border-b border-neutral-500 pb-[10px] text-neutral-600 placeholder:text-neutral-600 min-h-[38px] bg-white"
  >
    <input
      ref="input"
      class="text-base outline-none flex-1 !bg-white"
      :value="modelValue"
      type="search"
      aria-label="search"
      :placeholder="placeholder"
      @input="updateValue"
      @focus="handleFocus"
      @blur="handleBlur"
      @keyup.enter="emit('user-action:search')"
    />
    <Transition name="fade">
      <button v-if="modelValue.length > 0" @click="handleClear">
        <Icon name="close" size="md" :color="colors.neutral[600]" />
      </button>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import colors from '#tailwind-config/theme/colors'
export type InputStyles = 'white' | 'gray'

interface InputProps {
  modelValue: string
  placeholder?: string
}

withDefaults(defineProps<InputProps>(), {
  modelValue: '',
  isDisabled: false
})

const emit = defineEmits(['update:modelValue', 'user-action:search', 'user-action:searchClear'])

const updateValue = (event: Event) => {
  emit('update:modelValue', (event.target as HTMLInputElement).value)
}
const key = ref(0)
const input = ref<null | HTMLInputElement>(null)
const isFocused = ref(false)

function handleFocus() {
  isFocused.value = true
}

function handleBlur() {
  setTimeout(() => (isFocused.value = false))
}

function handleClear() {
  if (input.value) {
    input.value?.focus()
  }

  emit('user-action:searchClear')
}

onMounted(async() => {
  key.value += 1

  await nextTick(() => {
    input.value?.focus()
  })
})
</script>

<style lang="postcss">
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
</style>
