import { Client } from '@datocms/cma-client-node'
import { paths } from '../lib/paths'
import locales from '../i18n/locales'
import { ModelApiKey } from '../types/models'
import { LocaleCode } from '~/types/locales'

const defaultLocale = locales[0].code

export const getItemTypesByApiKeys = async (client: Client, apiKeys: ModelApiKey[]) => {
  const itemTypes = await client.itemTypes.list()
  const filteredItemTypes = itemTypes.filter((record) =>
    apiKeys.includes(record.api_key as ModelApiKey)
  )
  return filteredItemTypes
}

export const generateRoute = (record: any, localeCode: string, itemTypes: any[]) => {
  // Get path based on locale code and model api_key
  const modelApiKey = itemTypes.find((model) => model.id === record.item_type.id)
    ?.api_key as ModelApiKey
  const path = paths[localeCode as LocaleCode][modelApiKey].path

  // Exclude default locale code from route path (Based on i18n Strategy! prefix_except_default)
  const localePrefix = localeCode !== defaultLocale && localeCode

  // Generate route
  const route = '/' + [localePrefix, path, record.slug[localeCode]].filter(Boolean).join('/')
  return route
}

export const getAlgoliaIndexName = (locale: LocaleCode) => {
  return `index_${locale}`
}

export const getItemType = (record: any, localeCode: string, itemTypes: any[]) => {
  const modelApiKey = itemTypes.find((model) => model.id === record.item_type.id)
    ?.api_key as ModelApiKey

  const types = {
    page: {
      en: 'Destination',
      sl: 'Destinacija'
    },
    event: {
      en: 'Event',
      sl: 'Dogodek'
    },
    article: {
      en: 'News',
      sl: 'Novice'
    },
    offer: {
      en: 'Offer',
      sl: 'Ponudba'
    }
  }

  return types[modelApiKey][localeCode]
}
