import _transparent from "./colors/transparent.mjs"
import _current from "./colors/current.mjs"
import _gray from "./colors/gray.mjs"
import _black from "./colors/black.mjs"
import _white from "./colors/white.mjs"
import _neutral from "./colors/neutral.mjs"
import _yellow from "./colors/yellow.mjs"
import _blue from "./colors/blue.mjs"
import _green from "./colors/green.mjs"
import _red from "./colors/red.mjs"
import _primary from "./colors/primary.mjs"
import _secondary from "./colors/secondary.mjs"
import _tertiary from "./colors/tertiary.mjs"
import _disabled from "./colors/disabled.mjs"
import _dark from "./colors/dark.mjs"
const config = { "transparent": _transparent, "current": _current, "gray": _gray, "black": _black, "white": _white, "neutral": _neutral, "yellow": _yellow, "blue": _blue, "green": _green, "red": _red, "primary": _primary, "secondary": _secondary, "tertiary": _tertiary, "disabled": _disabled, "dark": _dark }
export { config as default, _transparent, _current, _gray, _black, _white, _neutral, _yellow, _blue, _green, _red, _primary, _secondary, _tertiary, _disabled, _dark }